<app-header></app-header>


<!--Section 1-->
<!--padding-top needed-->
<div class="section" style="padding-top: 100px">
  <div class="container">
    <div class="col-md-12">
      <div class="section-padding">
        <h1 class="prepaid-title"> About Line Clear Express Prepaid <br> <br> </h1>


        <p class="prepaid-text">


          Line Clear Express Prepaid is a new closed-loop payment method by Line Clear Express.
          <br><br>
          This payment method allows customers to pay within the maximum amount of credits (known as Line Clear Coins)
          in their account.
          <br><br>
          Line Clear Coins is the official virtual credit on Line Clear Express’ Order Management System (OMS) and can
          be purchased or topped up in advance in the OMS
          before payment or during the checkout process.
        </p>



      </div>



    </div>
  </div>
</div>


<!--Section 2 - Coin Image-->
<div class="section">
  <div class="container">
    <div class="col-md-12">
      <div class="row display-flex-center">


        <img id="prepaid-coin" class="" src="./assets/image/Line Clear Coin_Single Coin.png" data-aos="zoom-in-down">


        <div class="col align-self-center  mobile-center">
          <p class="coin-text">
            Every 100 Line Clear Coins is equivalent to RM1 <br><br>
            100 Line Clear Coins = RM1 <br><br>
            Customers will be rewarded with Line Clear Coins for every top up.
          </p>

        </div>
      </div>
    </div>
  </div>
</div>



<!--Section 3-->
<div class="section" style="background-color: whitesmoke;">

  <div class="container">
    <div class="col-md-12">

      <h1 class="prepaid-title"> Line Clear Coins Validity Limits and Rules <br> <br> </h1>


      <p class="prepaid-text">


        The following table shows the amount of top up in Ringgit Malaysia, Line Clear Coins entitled after each top
        up, Reward Coins earned and validity period for account and Reward Coins.

      </p>
      <br>
      <br>
      <!--Table-->
      <div class="table-responsive">
        <table class="">
          <thead>
            <tr class="">
              <th>Top Up Amount</th>
              <th>Coins Entitled</th>
              <th>Active Period</th>
              <th>Validity Period</th>
              <th>Reward Coins</th>
              <th>Reward Coins Validity Period</th>
              <th>Coin Rewards</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>RM 1,000</td>
              <td>100,000</td>
              <td>90 days</td>
              <td rowspan="6" style="vertical-align : middle; text-align:center;">180 days after expiry date and All
                Line Clear Coins are forfeited</td>
              <td>7,000</td>
              <td rowspan="7" style="vertical-align : middle; text-align:center;">All Reward Coins are valid
                for 7 days and will be
                forfeited after this period.</td>
              <td>7%</td>

            </tr>
            <tr>
              <td>RM 300</td>
              <td>30,000</td>
              <td>90 days</td>
              <td>2,100</td>
              <td>7%</td>

            </tr>
            <tr>
              <td>RM 100</td>
              <td>10,000</td>
              <td>60 days</td>
              <td>600</td>
              <td>6%</td>
            </tr>
            <tr>
              <td>RM 50</td>
              <td>5,000</td>
              <td>60 days</td>
              <td>150</td>
              <td>3%</td>
            </tr>
            <tr>
              <td>RM 20</td>
              <td>2,000</td>
              <td>30 days</td>
              <td>60</td>
              <td>3%</td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>
  </div>
</div>


<!--Section 4 - Account Lifecycle Image-->
<div class="section">
  <div class="container">
    <div class="col-md-12">
      <div class="row display-flex-center">
        <img src="./assets/image/account_lifecycle_2024.jpg">
      </div>
    </div>
  </div>
</div>

<!--Section Last-->
<div class="section" style="background-color: whitesmoke;">
  <div class="container">
    <div class="col-md-12">
      <div class="">
        <h1 class="prepaid-title"> Glossary <br> <br> </h1>

        <!--Table-->
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th>Glossary</th>
                <th>Description</th>

              </tr>
            </thead>
            <tbody>
              <tr>
                <td style="vertical-align : middle; text-align:center;">Line Clear Coins</td>
                <td>
                  <ul>
                    <li>The official virtual currency on Line Clear Express’ Order Management
                      System (OMS). Can be purchased or topped up in advance in the OMS only
                      before payment or during the checkout process.</li>
                    <li>Every 100 Line Clear Coins is equivalent to RM1. The minimum top up
                      amount is RM20. </li>
                  </ul>

                </td>


              </tr>
              <tr>
                <td>Reward <br> Coins</td>
                <td>
                  <ul>
                    <li>Reward Coins are incentive coins given to the customer after each top up
                      for their continuous support in using Line Clear Express Prepaid service to
                      make payments. </li>
                  </ul>



                </td>

              </tr>
              <tr>
                <td>Active</td>
                <td>
                  <ul>
                    <li>Line Clear Express Prepaid account will be activated when customer
                      performs a top-up to the prepaid account. This will change the account
                      validity status to an active period.</li>
                  </ul>


                </td>
              </tr>
              <tr>
                <td>Inactive </td>
                <td>
                  <ul>
                    <li>Line Clear Express Prepaid account will go into inactive period when the
                      prepaid account expires between Day 31 to Day 90, depending on the
                      topped-up amount.</li>
                    <li>The inactive period serves the purpose of an interval for customer to top up
                      upon account expiry before account suspension.</li>
                    <li>Customers will need to make a minimum top up of RM20 during the inactive
                      period in order to reactivate the account.</li>
                    <li>Any remaining amount left in the Prepaid Account that has not expire will be
                      reinstated.</li>
                  </ul>



                </td>
              </tr>
              <tr>
                <td>Validity</td>
                <td>
                  <ul>
                    <li>The validity period refers to the time during which the Line Clear Coins and
                      Reward Coins earned are intended to be valid. </li>
                  </ul>

                </td>
              </tr>
              <tr>
                <td>Expire</td>
                <td>
                  <ul>
                    <li>All Line Clear Coins and Reward Coins are subject to expiry dates. </li>
                    <li>When the date of expiry reaches, the remaining Line Clear Coins and
                      Reward Coins will be forfeited. </li>
                  </ul>



                </td>
              </tr>
              <tr>
                <td>Suspended</td>
                <td>
                  <ul>
                    <li>After 180 days from the last day of active period, the Line Clear Express
                      Prepaid account will be suspended and all remaining Line Clear Coins will
                      be forfeited. </li>
                    <li>Line Clear Express Prepaid account will be activated when customer
                      performs a top-up to the prepaid account. This will change the account
                      validity status to an active period.</li>
                  </ul>


                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <h1 class="prepaid-text">
          <a href="javascript:;" (click)="goToTnc()" style="color: #40BFEF">
            <p>Click here to read the full terms and conditions </p>
          </a>
        </h1>

      </div>



    </div>
  </div>
</div>


<app-footer></app-footer>